import request from '../utils/request';

export const login = query => {
    return request({
        url: '/api/operateToJson/login',
        method: 'post',
        data: query
    });
};

export const czList = query => {
    return request({
        url: '/api/list/czList',
        method: 'post',
        data: query
    });
};

export const txList = query => {
    return request({
        url: '/api/list/txList',
        method: 'post',
        data: query
    });
};

export const discountList = query => {
    return request({
        url: '/api/list/discountList',
        method: 'post',
        data: query
    });
};

export const dailiDetail = query => {
    return request({
        url: '/api/detail/dailiDetail',
        method: 'post',
        data: query
    });
};

export const sysApp = query => {
    return request({
        url: '/api/detail/sysApp',
        method: 'post',
        data: query
    });
};

export const bankCollectList = query => {
    return request({
        url: '/api/list/bankCollectList',
        method: 'post',
        data: query
    });
};

export const czUsers = query => {
    return request({
        url: '/api/operate/czUsers',
        method: 'post',
        data: query
    });
};

export const getTxBank = query => {
    return request({
        url: '/api/detail/getTxBank',
        method: 'post',
        data: query
    });
};
export const bankTxAddEdit = query => {
    return request({
        url: '/api/operate/bankTxAddEdit',
        method: 'post',
        data: query
    });
};

export const xgPwd = query => {
    return request({
        url: '/api/operate/xgPwd',
        method: 'post',
        data: query
    });
};


export const xgDrawPwd = query => {
    return request({
        url: '/api/operate/xgDrawPwd',
        method: 'post',
        data: query
    });
};


export const myTeamUserList = query => {
    return request({
        url: '/api/list/myTeamUserList',
        method: 'post',
        data: query
    });
};


export const syList = query => {
    return request({
        url: '/api/list/syList',
        method: 'post',
        data: query
    });
};

export const orderList = query => {
    return request({
        url: '/api/list/orderList',
        method: 'post',
        data: query
    });
};

export const getUser = query => {
    return request({
        url: '/api/detail/getUser',
        method: 'post',
        data: query
    });
};

export const getContent = query => {
    return request({
        url: '/api/detail/getContent',
        method: 'post',
        data: query
    });
};

export const lowerLevelReport = query => {
    return request({
        url: '/api/list/lowerLevelReport',
        method: 'post',
        data: query
    });
};

export const UsersAmountChangeList = query => {
    return request({
        url: '/api/list/UsersAmountChangeList',
        method: 'post',
        data: query
    });
};

export const usersDailiGuanli = query => {
    return request({
        url: '/api/list/usersDailiGuanli',
        method: 'post',
        data: query
    });
};


export const userOrderList = query => {
    return request({
        url: '/api/list/userOrderList',
        method: 'post',
        data: query
    });
};

export const bannerList = query => {
    return request({
        url: '/api/list/bannerList',
        method: 'post',
        data: query
    });
};

export const syListNewPaihang = query => {
    return request({
        url: '/api/list/syListNewPaihang',
        method: 'post',
        data: query
    });
};

export const orderListNewPaihang = query => {
    return request({
        url: '/api/list/orderListNewPaihang',
        method: 'post',
        data: query
    });
};

export const syPaihangList = query => {
    return request({
        url: '/api/list/syPaihangList',
        method: 'post',
        data: query
    });
};

export const newsHeadList = query => {
    return request({
        url: '/api/list/newsHeadList',
        method: 'post',
        data: query
    });
};

export const newsHeadListUser = query => {
    return request({
        url: '/api/list/newsHeadListUser',
        method: 'post',
        data: query
    });
};

export const register = query => {
    return request({
        url: '/api/operateToJson/register',
        method: 'post',
        data: query
    });
};


